<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
    />
    <base-btn
      data-aos="flip-up"
      data-aos-duration="1000"
      href="mailto:team@y-squared.org?subject=Y-Squared%20Enquiry"
      target="_blank"
    >
      Click here to  Send message
    </base-btn>
  </div>
</template>

<script>
  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'MAIL US YOUR MESSAGE',
      },
    },
  }
</script>
