<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  export default {
    name: 'App',

    metaInfo: {
      title: 'MULLIGAN HOSPITALITY',
      titleTemplate: '%s | MHG',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          name: 'description',
          content: 'MHG is Irelands premier hospitality group.  We specialise in Hotels, Bars & Restaurant with more than seven locations in Dublin City.',
        },
        {
          name: 'keywords',
          content:
            'Bars, Restatraunts, Dublin, Ireland, Mulligan, Hospotality, Hotels',
        },
        { name: 'author', content: 'Y-Squared https://www.y-squared.org/' },
      ],
    },
  }
</script>

<style lang="sass">
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important
</style>
