import WebFontLoader from 'webfontloader'

// async load fonts
WebFontLoader.load({
  google: {
    families: [
      'Montserrat:300,400,600,700,800,900&display=swap',
    ],
  },
})
