import 'firebase/auth'
import 'firebase/firestore'
import { firebase } from '@firebase/app'
import '@firebase/auth'

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: 'AIzaSyDNY8y9qj6ULBbaKhq2b9YxgtQMq4U70aQ',
    authDomain: 'mhglogin.firebaseapp.com',
    projectId: 'mhglogin',
    storageBucket: 'mhglogin.appspot.com',
    messagingSenderId: '129086007465',
    appId: '1:129086007465:web:c5a010580797309b49e1b8',
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
const postsCollection = db.collection('posts')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')

// export utils/refs
export {
  db,
  auth,
  usersCollection,
  postsCollection,
  commentsCollection,
  likesCollection,
}
