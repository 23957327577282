// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { auth } from '../firebase'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '/',
          name: 'Home',
          component: () => import('@/views/home/Index.vue'),
          meta: {
            // requiresAuth: true,
          },
        },
        {
          path: 'venues',
          name: 'Venues',
          component: () => import('@/views/venues/Index.vue'),
          meta: {
            url: require('@/assets/venues.jpg'),
            // requiresAuth: true,
          },
        },
        {
          path: 'hotels',
          name: 'Hotels',
          component: () => import('@/views/hotels/Index.vue'),
          meta: {
            url: require('@/assets/hotel.jpg'),
          },
        },
        {
          path: 'work-with-us',
          name: 'Work With Us',
          component: () => import('@/views/work-with-us/Index.vue'),
          meta: { url: require('@/assets/m&hdrink.jpg') },
        },
        {
          path: 'our-story',
          name: 'Our Story',
          component: () => import('@/views/our-story/Index.vue'),
          meta: { url: require('@/assets/barge10.jpg') },
        },
        {
          path: 'contact-us',
          name: 'Contact Us',
          component: () => import('@/views/contact-us/Index.vue'),
          meta: { url: require('@/assets/MH51.jpg') },
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
